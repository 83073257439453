import axios from 'axios'
import { MessageBox, Message } from 'element-ui'
import store from '@/store'
import {getToken, setToken, removeToken} from '@/common/utils/auth'
import Vue from "vue"
import util from './util.js'

// create an axios instance
const instance = axios.create({
  // baseURL: Vue.prototype.$baseUrl,
  timeout: 5*60000
})

// 请求过滤器
instance.interceptors.request.use(
  config => {
    let token = getToken();
    if (util.isNotEmpty(token)) {
      config.headers['X-Token'] = getToken() // 添加自定义头
    }
    return config
  },
  error => {
    console.log("request err: "+error) // 输出error信息
    return Promise.reject(error)
  }
)

// response 拦截器
instance.interceptors.response.use(
  response => {
    const res = response.data;
    // setToken("xxx")
    if (res.code == -1) {
      MessageBox.confirm('验证登录信息失败，请重新登录。', '提示', {
        confirmButtonText: '重新登录',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        removeToken();
        location.reload()
      }).catch(()=>{})
    } else {
      return res;
    }
  },
  error => {
    if(error.response){
      let status = error.response.status;
      if(status == 403){
        Message({
          // message: error.message,
          message: "权限不足,请联系管理员!",
          type: 'error',
          showClose: true,
          duration: 10 * 1000
        });

        return Promise.reject(error);
      }
    }else{
      Message({
        // message: error.message,
        message: "网络错误",
        type: 'error',
        showClose: true,
        duration: 10 * 1000
      })
      return Promise.reject(error)
    }

  }
)

export default instance
